import { FC } from 'react';
import { Body1 } from '@ehi/ui';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { AlertDialogVariant, BaseDialogProps } from 'components/shared/alert/AlertDialogTypes';
import { useTranslations } from 'components/shared/i18n';
import { AlertDialog } from 'components/shared/alert/AlertDialog';

export type ResponseMessageDialogProps = BaseDialogProps & {
  title?: string;
  additionalMessage?: string;
  responseMessages?: ResponseMessage[];
  variant?: AlertDialogVariant;
  primaryActionText?: string;
  secondaryActionText?: string;
};

export const ResponseMessageDialog: FC<ResponseMessageDialogProps> = ({
  responseMessages,
  additionalMessage,
  ...rest
}) => {
  const { t } = useTranslations();

  const collectErrorInformation = () => {
    return responseMessages?.length ? JSON.stringify(responseMessages) : '';
  };

  const errorList = responseMessages?.length ? (
    responseMessages.map((err: ResponseMessage, index: number) => (
      <li key={index}>{err?.localizedMessage ?? err?.supportInformation ?? t('error.unknownError')}</li>
    ))
  ) : (
    <li>{t('error.unknownError')}</li>
  );

  const content = (
    <>
      {additionalMessage && <Body1 sx={{ paddingBottom: (theme) => theme.spacing(1) }}>{additionalMessage}</Body1>}
      {errorList}
    </>
  );

  return (
    <AlertDialog description={content} variant={'error'} collectErrorInformation={collectErrorInformation} {...rest} />
  );
};
