import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useCallback } from 'react';
import { VEHICLE_BOOKING_ERROR_CODES } from 'utils/errorUtils';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useResSnackbarContext } from 'context/resSnackbar/ResSnackbarContext';
import { EditorIssue, RateSource } from 'services/booking/bookingTypes';
import { deleteVehicleClassSelection, modifyRateSource } from 'services/booking/bookingService';
import { NEGOTIATED } from 'components/flexFlow/driver/driverSearch/driverSearchUtils';
import { parseUrn } from 'utils/urnUtils';
import { bookingIssues } from 'services/booking/bookingIssues';

export type AddOrModifyRateSourceProps = {
  accountNumber: string;
  handleCloseModal: () => void;
};

export type useRateAndBillingHookReturn = {
  addOrModifyRateSource: (props: AddOrModifyRateSourceProps) => Promise<void>;
};

export const useRateSource = (): useRateAndBillingHookReturn => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { setSnackBarRes } = useResSnackbarContext();

  const addOrModifyRateSource = useCallback(
    async ({ accountNumber, handleCloseModal }: AddOrModifyRateSourceProps): Promise<void> => {
      const rateSourceRequestBody: RateSource = {
        type: NEGOTIATED,
        account: accountNumber,
      };
      const { data, errors } = await updateAndRefresh(() => modifyRateSource(bookingEditorId, rateSourceRequestBody));

      if (!errors) {
        handleCloseModal();

        if (data?.issue) {
          VEHICLE_BOOKING_ERROR_CODES.forEach((issueCode) => {
            if (data.issue.find((issue: EditorIssue) => parseUrn(issue.issueCode) === issueCode)) {
              const displayMessage = bookingIssues.find(
                (bookingIssue) => bookingIssue.code === issueCode
              )?.translationKey;
              updateAndRefresh(async () => deleteVehicleClassSelection(bookingEditorId)).then(async (result) => {
                if (result.errors) {
                  await showAlert({ responseMessages: result.errors });
                }
              });
              displayMessage &&
                setSnackBarRes({
                  message: t(displayMessage),
                  isOpen: true,
                });
            }
          });
        }
      } else {
        await showAlert({
          variant: 'error',
          description: errors?.[0]?.localizedMessage || '',
        });
      }
    },
    [bookingEditorId, setSnackBarRes, showAlert, t, updateAndRefresh]
  );

  return {
    addOrModifyRateSource,
  };
};
