import {
  AdditionalDriver,
  Contact,
  EditorBusinessPayer,
  Equipment,
  PersonPayer,
  Pickup,
  ProtectionsForCategory,
  RateSource,
  Renter,
  Reservation,
  ReservationEditor,
  ReservationEditorCreator,
  RetrieveVehicleRatesResponse,
  Return,
  SaveToReservation,
  SelectVehicleClass,
} from 'services/booking/bookingTypes';
import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import { EHI_ACCEPT_PROTECTED_DATA, PROTECTED_DATA_VALUE } from 'services/headerConstants';
import { safelyCatchError } from 'utils/errorUtils';
import { HttpStatusCode } from 'services/types/EhiErrorsTypes';
import { AxiosResponse } from 'axios';
import { getWarningsFromResponseHeaders } from 'utils/warningParserUtils';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';

const BASE_PATH = '/rental/booking';
const RESERVATION_PATH = '/reservation';
const EDITOR_PATH = '/reservationEditor';

export const retrieveReservation = async (reservationNumber: string): Promise<Reservation | undefined> => {
  try {
    const { data } = await getDefaultAxiosInstance().get(`${BASE_PATH}${RESERVATION_PATH}/${reservationNumber}`, {
      genericUrl: `${BASE_PATH}${RESERVATION_PATH}/{reservationNumber}`,
      headers: {
        [EHI_ACCEPT_PROTECTED_DATA]: PROTECTED_DATA_VALUE,
      },
    });

    return data;
  } catch (e) {
    const errorResponse = safelyCatchError(e);
    if (errorResponse.status === HttpStatusCode.NotFound) {
      // service will throw a 404 when reservation is not found
      return undefined;
    } else {
      throw e;
    }
  }
};

export const createNewReservationEditor = async (data?: ReservationEditorCreator) => {
  const response = await getDefaultAxiosInstance().post(`${BASE_PATH}${EDITOR_PATH}`, data, {
    genericUrl: `${BASE_PATH}${EDITOR_PATH}`,
  });
  // The api only returns the data, but the headers have actual response data in them for this call
  // we are adding the headers to the "data" so it is accessible later on
  return { ...response, data: { data: response.data, headers: response.headers } } as AxiosResponse;
};

export const updatePickupInformation = async (editorId: string, data: Pickup): Promise<void> => {
  return getDefaultAxiosInstance().put(`${BASE_PATH}${EDITOR_PATH}/${editorId}/pickup`, data, {
    genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}/pickup`,
  });
};

export const updateReturnInformation = async (editorId: string, data: Return): Promise<void> => {
  return getDefaultAxiosInstance().put(`${BASE_PATH}${EDITOR_PATH}/${editorId}/return`, data, {
    genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}/return`,
  });
};

export type RetrieveEditor = {
  data: ReservationEditor;
  ehiMessages?: ResponseMessage[];
};

export const retrieveEditor = async (editorId: string): Promise<RetrieveEditor> => {
  const { data, headers } = await getDefaultAxiosInstance().get(`${BASE_PATH}${EDITOR_PATH}/${editorId}`, {
    genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}`,
  });
  return { data, ehiMessages: getWarningsFromResponseHeaders(headers) };
};

export const updateContactInformation = async (editorId: string, data: Contact): Promise<void> => {
  return getDefaultAxiosInstance().put(`${BASE_PATH}${EDITOR_PATH}/${editorId}/contact`, data, {
    genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}/contact`,
  });
};

export const updatePayersPerson = async (editorId: string, data: PersonPayer): Promise<void> => {
  return getDefaultAxiosInstance().put(`${BASE_PATH}${EDITOR_PATH}/${editorId}/payers/person`, data, {
    genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}/payers/person`,
  });
};

export const updateBusinessPayers = async (editorId: string, data: EditorBusinessPayer[]): Promise<void> => {
  return getDefaultAxiosInstance().put(`${BASE_PATH}${EDITOR_PATH}/${editorId}/payers/business`, data, {
    genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}/payers/business`,
  });
};

export const associateRenterToReservationEditor = async (editorId: string, data: Renter): Promise<void> => {
  return getDefaultAxiosInstance().post(`${BASE_PATH}${EDITOR_PATH}/${editorId}/associate-renter`, data, {
    genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}/associate-renter`,
  });
};

export const updateAdditionalDrivers = async (editorId: string, data: AdditionalDriver[]): Promise<void> => {
  return getDefaultAxiosInstance().put(`${BASE_PATH}${EDITOR_PATH}/${editorId}/additionalDriver`, data, {
    genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}/additionalDriver`,
  });
};

export const retrieveVehicleRates = async (editorId: string): Promise<RetrieveVehicleRatesResponse> => {
  const { data } = await getDefaultAxiosInstance().post(
    `${BASE_PATH}${EDITOR_PATH}/${editorId}/retrieve-vehicle-rates`,
    [],
    {
      genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}/retrieve-vehicle-rates`,
    }
  );

  return data;
};

export const updateVehicleClassSelection = async (editorId: string, data: SelectVehicleClass): Promise<void> => {
  return getDefaultAxiosInstance().put(`${BASE_PATH}${EDITOR_PATH}/${editorId}/vehicleClassSelection`, data, {
    genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}/vehicleClassSelection`,
  });
};

export const deleteVehicleClassSelection = async (editorId: string): Promise<void> => {
  return getDefaultAxiosInstance().delete(`${BASE_PATH}${EDITOR_PATH}/${editorId}/vehicleClassSelection`, {
    genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}/vehicleClassSelection`,
  });
};

export const saveToReservation = async (editorId: string, data: SaveToReservation) => {
  const response = await getDefaultAxiosInstance().post(
    `${BASE_PATH}${EDITOR_PATH}/${editorId}/save-to-reservation`,
    data,
    {
      genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}/save-to-reservation`,
    }
  );
  // The api only returns the data, but the headers have actual response data in them for this call
  // we are adding the headers to the "data" so it is accessible later on
  return { ...response, data: { data: response.data, headers: response.headers } } as AxiosResponse;
};

export const updateProtections = async (editorId: string, data: ProtectionsForCategory): Promise<void> => {
  return getDefaultAxiosInstance().put(`${BASE_PATH}${EDITOR_PATH}/${editorId}/addOns/protections/selected`, data, {
    genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}/addOns/protections/selected`,
  });
};

export const updateEquipment = async (editorId: string, data: Equipment[]): Promise<void> => {
  return getDefaultAxiosInstance().put(`${BASE_PATH}${EDITOR_PATH}/${editorId}/addOns/equipment/selected`, data, {
    genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}/addOns/equipment/selected`,
  });
};

export const modifyRateSource = async (editorId: string, data: RateSource): Promise<void> => {
  return getDefaultAxiosInstance().post(`${BASE_PATH}${EDITOR_PATH}/${editorId}/modify-rate-source`, data, {
    genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}/modify-rate-source`,
  });
};

export const updateRemarkForReservationEditor = async (editorId: string, remark: string): Promise<void> => {
  return getDefaultAxiosInstance().put(
    `${BASE_PATH}${EDITOR_PATH}/${editorId}/remark`,
    { remark },
    {
      genericUrl: `${BASE_PATH}${EDITOR_PATH}/{editorId}/remark`,
    }
  );
};
