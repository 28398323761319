import { SearchResult } from 'services/renter/driverProfile/driverProfileTypes';
import { Brand, LoyaltyProgram } from 'utils/constants';

export const addAdditionalDriverError = 'add additional driver error';

export const DRIVER_PROFILE = 'DRIVER_PROFILE';
export const NEGOTIATED = 'NEGOTIATED';
export const LOYALTY = 'loyalty';
export const CURRENT = 'current';
export const BOOK2077 = 'BOOK2077';
export const PRIMARY_DRIVER = 'primaryDriver';
export const RATE_SOURCE = 'rateSource';

export const prioritizeByBrand = (profiles: SearchResult[], rentalBrand: string | undefined): SearchResult[] => {
  const loyaltyProfiles: SearchResult[] = [];
  const nonLoyaltyProfiles: SearchResult[] = [];

  profiles.forEach((profile) => {
    const loyaltyType = profile.loyaltyMembership?.type;

    if (loyaltyType) {
      if (
        (rentalBrand === Brand.ENTERPRISE &&
          (loyaltyType === LoyaltyProgram.ENTERPRISE_PLUS || loyaltyType === LoyaltyProgram.EMERALD_CLUB)) ||
        (rentalBrand === Brand.NATIONAL && loyaltyType === LoyaltyProgram.EMERALD_CLUB) ||
        (rentalBrand === Brand.ALAMO && loyaltyType === LoyaltyProgram.ALAMO_INSIDERS) ||
        !rentalBrand
      ) {
        loyaltyProfiles.push(profile);
      }
    } else {
      nonLoyaltyProfiles.push(profile);
    }
  });

  return [...loyaltyProfiles, ...nonLoyaltyProfiles];
};
