import { FC, useState } from 'react';
import {
  AlertAccordion,
  AlertAccordionSummary,
  AlertBadge,
  AlertContainer,
  AlertListItem,
  AlertTitle,
} from 'components/shared/reservationAlerts/ReservationAlerts.styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Body2, ehiTheme, Subtitle2 } from '@ehi/ui';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useReservationAlerts } from 'components/shared/reservationAlerts/useReservationAlerts';
import { useTranslations } from 'components/shared/i18n';
import Box from '@mui/material/Box';

export const ReservationAlerts: FC = () => {
  const { t } = useTranslations();
  const [alertsRead, setAlertsRead] = useState(false);
  const { alerts } = useReservationAlerts();

  return !alerts.length ? (
    <></>
  ) : (
    <AlertContainer data-testid={'reservationAlerts'}>
      <AlertAccordion
        disableGutters={true}
        onChange={(expanded) => {
          if (expanded) {
            setAlertsRead(true);
          }
        }}>
        <AlertAccordionSummary
          data-testid={'reservationAlertsTitle'}
          expandIcon={<ExpandMoreIcon style={{ color: ehiTheme.palette.info.contrastText }} />}
          aria-controls='panel1-content'
          id='panel1-header'>
          <Box style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
            <InfoOutlinedIcon />
            <AlertTitle>{t('reservationAlerts.title')}</AlertTitle>
            {alertsRead ? (
              <Box>
                <AlertTitle data-testid={'totalAlerts'}>{`(${alerts.length})`}</AlertTitle>
              </Box>
            ) : (
              <AlertBadge variant={'dot'} data-testid={'unreadAlertsBadge'}>
                <AlertTitle data-testid={'totalAlerts'}>{`(${alerts.length})`}</AlertTitle>
              </AlertBadge>
            )}
          </Box>
        </AlertAccordionSummary>
        {alerts.map((alert, i) => {
          return (
            <AlertListItem data-testid={`alertListItem-${i + 1}`} divider={i < alerts.length - 1} key={i}>
              <Subtitle2 style={{ paddingBottom: ehiTheme.spacing(1) }}>{alert?.title}</Subtitle2>
              <Body2>{alert?.message}</Body2>
            </AlertListItem>
          );
        })}
      </AlertAccordion>
    </AlertContainer>
  );
};
