import { parseUrn } from 'utils/urnUtils';
import { ReservationAccount, ReservationConfiguration } from 'services/businessAccount/businessAccountTypes';

export type RateAndBillingInfo = {
  urn?: string;
  number?: string;
  name?: string;
  type?: string;
  reservationConfiguration?: ReservationConfiguration;
};

export const transformRateSourceInfoFromApi = (accountDetails: ReservationAccount): RateAndBillingInfo => {
  return {
    urn: accountDetails.urn,
    number: parseUrn(accountDetails.urn),
    name: accountDetails.name,
    type: parseUrn(accountDetails.type),
    reservationConfiguration: accountDetails.reservationConfiguration,
  };
};
